@charset "UTF-8";
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.l-lowerCaption__title, .c-topicspath {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/*
ユーティリティ系おまとめファイル
*/
.c-topicspath {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0px;
  text-align: left;
  top: 75%;
  color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 768px) {
  .c-topicspath {
    text-align: left;
  }
}
.c-topicspath li {
  display: inline-block;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .c-topicspath li {
    font-size: 0.7em;
  }
}
.c-topicspath li::after {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  display: inline-block;
  margin-left: 8px;
}
.c-topicspath li:last-child::after {
  content: none;
}
.c-topicspath li a {
  color: #FFFFFF;
}

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 154px;
  background-image: url("/inc/image/common/bg_lower.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 45px;
}
.l-lowerCaption__title {
  color: #fff;
  border-left: solid 9px #FFFFFF;
  max-width: 1170px;
  text-align: left;
  padding: 0 25px;
  line-height: 110%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .l-lowerCaption__title {
    width: 100%;
    padding: 0 20px;
  }
}

#activity .archive_menu {
  background-color: #F2F2F2;
  padding: 20px;
  border-radius: 10px;
}
#activity .archive_menu h4 {
  border-bottom: solid 1px #1453A7;
  color: #1453A7;
}
#activity .archive_menu li {
  color: #000000;
  line-height: 1.8em;
}
#activity .archive_menu a {
  color: #000000;
}
#activity .p-archiveList__item {
  border: solid 1px #1453A7;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  padding: 15px;
  color: #000000;
}
#activity .p-archiveList__item img {
  height: 128px;
  width: 100%;
}
#activity .p-archiveList__item span {
  font-weight: 600;
  color: #1453A7;
  display: inline-block;
}
#activity .p-archiveList__item:hover {
  opacity: 0.7;
}
#activity .p-archive__data {
  display: block;
  margin: -10px 0 30px 0;
}
#activity .c-archive {
  margin-top: 30px;
}

#organization .p-organizationList__item {
  border: solid 1px #1453A7;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  padding: 15px;
  color: #000000;
}
#organization .p-organizationList__item img {
  height: 150px;
  width: 100%;
  margin-bottom: 15px;
}
#organization .p-organizationList__item span {
  font-weight: 600;
  color: #1453A7;
  font-size: 30px;
  display: block;
  text-align: center;
  position: relative;
}
#organization .p-organizationList__item span::after {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  transform: translateY(-50%);
  transition: right 0.2s ease;
  line-height: 0.1;
  position: absolute;
  right: 0;
  bottom: 15px;
  font-size: 75%;
}
#organization .p-organizationList__item:hover {
  opacity: 0.7;
}

h1 {
  font-size: 35px;
}

.c-ttl-main {
  font-size: 25px;
  color: #1453A7;
  border-bottom: solid 1px #1453A7;
  display: inline-block;
  padding-bottom: 8px;
}

.c-ttl-padding {
  padding-right: 30px;
}

.c-ttl-middle {
  color: #1453A7;
  display: inline-block;
  border-bottom: solid 1px #1453A7;
  padding-right: 30px;
}

.c-ttl-mini {
  color: #1453A7;
}